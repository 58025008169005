import React from "react";
import Helmet from "react-helmet";
import "../../assets/scss/init.scss";
import "../../assets/scss/footer.scss";
import CookieConsent, { Cookies } from "react-cookie-consent";
class Layout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className="layout">
        <Helmet defaultTitle="Blog by Box Piper" />
        {children}
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="gatsby-gdpr-google-analytics"
          style={{ background: "#2B373B" }}
          buttonStyle={{
            color: "#4e503b",
            fontSize: "15px",
            fontWeight: "bold",
          }}
          expires={150}
          acceptOnScroll={true}
          acceptOnScrollPercentage={10}
        >
          This website uses cookies to enhance the user experience.{" "}
          <a
            href={`https://www.cookiesandyou.com?ref=https://www.boxpiper.com`}
            target="_blank"
          >
            Learn more
          </a>
        </CookieConsent>
      </div >
    );
  }
}

export default Layout;
